<template>
     <div class="initiate_inquiry">
          <div class="inquiry_header">
               <div class="header_bg" :style="{ backgroundImage: `url(${header_bg})` }"></div>
          </div>

          <div class="initiate_inquiry_z">
               <div class="init_main">
                    <div class="bd1 flex-col">
                         <vali-input-group ref="valiGroup" :validateForm="validate">
                              <div class="bd2 flex-col">
                                   <div class="outer1 flex-row justify-between">
                                        <div class="layer1 flex-col"></div>
                                        <span class="info1">{{ L['询盘需求'] }}</span>
                                   </div>
                                   <div class="outer2 flex-row" id="title">
                                        <div class="bd3 flex_row_start_start">
                                             <div class="flex_row_start_center bd31">
                                                  <span class="word2">*</span>
                                                  <span class="info2">{{ L['询盘标题：'] }}</span>
                                             </div>
                                             <div class="bd4 flex_row_start_center">
                                                  <vali-input type="text" v-model="inqDTO.title" maxlength="50" showCount
                                                       class="bd4_title" label="title"></vali-input>
                                             </div>
                                        </div>
                                   </div>
                                   <span class="info3">{{ L['产品清单'] }}</span>
                                   <div class="outer3 flex-col">
                                        <div class="bd6 flex-col">
                                             <div class="outer30 flex-col justify-between">
                                                  <div class="block2 flex-row justify-between">
                                                       <div class="main1">
                                                            <span class="word29">*</span>
                                                            <span class="word30">{{ L['产品名称'] }}</span>
                                                       </div>
                                                       <span class="word31">{{ L['规格/型号'] }}</span>
                                                       <span class="txt16">{{ L['品牌'] }}</span>
                                                       <div class="main2">
                                                            <span class="info20">*</span>
                                                            <span class="word32">{{ L['购买数量'] }}</span>
                                                       </div>
                                                       <div class="main3">
                                                            <span class="word33">*</span>
                                                            <span class="word34">{{ L['期望单价(元)'] }}</span>
                                                       </div>
                                                  </div>
                                                  <div class="block3 flex-col" v-for="(pro, proIdx) in inqDTO.productList"
                                                       :id="pro.key" :key="pro.key">
                                                       <div class="layer16 flex-row">
                                                            <div class="section12 flex-col">
                                                                 <vali-input type="text" v-model="pro.productName"
                                                                      maxlength="30" label="productName"
                                                                      :definekey="pro.key"></vali-input>
                                                            </div>

                                                            <div class="section13 flex-col">
                                                                 <input type="text" v-model="pro.spec" maxlength="50"
                                                                      :definekey="pro.key">
                                                            </div>
                                                            <div class="section13 flex-col">
                                                                 <input type="text" v-model="pro.brandName" maxlength="30"
                                                                      :definekey="pro.key">
                                                            </div>

                                                            <div style="margin-left: 68px;">
                                                                 <div class="section15 flex-col">
                                                                      <div class="layer17 flex_row_between_center">
                                                                           <vali-input type="number" v-model="pro.buyNum"
                                                                                max="99999" min="1" :definekey="pro.key"
                                                                                label="buyNum" mode="int"
                                                                                class="vali_input_buyNum"></vali-input>

                                                                           <div class="flex_row_between_center con1">
                                                                                <el-autocomplete v-model="pro.unitName"
                                                                                     :fetch-suggestions="querySearch"
                                                                                     popper-class="my-autocomplete"
                                                                                     placeholder="请选择"
                                                                                     @select="handleSelect($event, pro)"
                                                                                     @blur="autoBlur($event, pro)"
                                                                                     maxlength="5" :key="pro.key">
                                                                                     <template #suffix>
                                                                                          <el-icon class="el-input__icon">
                                                                                               <span
                                                                                                    class="el-icon-edit"></span>
                                                                                          </el-icon>
                                                                                     </template>
                                                                                     <template #default="{ item }">
                                                                                          <div
                                                                                               style="width: 50px;text-align: left;">
                                                                                               {{ item }}
                                                                                          </div>
                                                                                     </template>
                                                                                </el-autocomplete>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>

                                                            <div class="container1">
                                                                 <div class="flex-row ">
                                                                      <div class="section16 flex-col">
                                                                           <vali-input type="number" :placeholder="L['最小值']"
                                                                                v-model="pro.priceMin" :definekey="pro.key"
                                                                                label="priceMin" mode="float" max="9999999"
                                                                                :related="priceRelate(pro)"></vali-input>
                                                                      </div>
                                                                      <span class="info22">~</span>
                                                                      <div class="section17 flex-col">
                                                                           <vali-input type="number" :placeholder="L['最大值']"
                                                                                v-model="pro.priceMax" :definekey="pro.key"
                                                                                mode="float" max="9999999"
                                                                                label="priceMax"></vali-input>
                                                                      </div>
                                                                 </div>
                                                            </div>

                                                            <div class="section18" v-show="proIdx > 0">
                                                                 <img src="@/assets/inquiry/inq_icon9.png"
                                                                      @click="removeProPanel(proIdx)">
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div class="outer5 flex-col" @click="addProductPanel"
                                        v-show="inqDTO.productList && inqDTO.productList.length <= 30">
                                        <span class="info6">+&nbsp;{{ L['添'] }}&nbsp;{{ L['加'] }}</span>
                                   </div>
                                   <div class="outer6g flex_row_start_start">
                                        <div class="section1g">
                                             <span class="word6g">*</span>
                                             <span class="word7g">{{ L['期望收货日期'] }}:</span>
                                        </div>

                                        <div class="section2g" id="expectedDeliverDate">
                                             <div :class="{ validate: validate.expectedDeliverDate.flag }">
                                                  <div class="flex-col vBorder section21g">
                                                       <el-date-picker v-model="inqDTO.expectedDeliverDate" type="date"
                                                            :placeholder="L['请选择']" :disabled-date="disabledDate"
                                                            :shortcuts="shortcuts"
                                                            @change="inputEvent('expectedDeliverDate')">
                                                       </el-date-picker>
                                                  </div>
                                                  <span class="error_msg">{{ validate.expectedDeliverDate.errMsg }}</span>
                                             </div>
                                        </div>

                                        <div class="section3g">
                                             <span class="word8g">*</span>
                                             <span class="word9g">{{ L['报价截止时间'] }}:</span>
                                        </div>
                                        <div class="section2g flex-col" id="priceEndTime">
                                             <div :class="{ validate: validate.priceEndTime.flag }">
                                                  <div class="flex-col vBorder section21g">
                                                       <el-date-picker v-model="inqDTO.priceEndTime" type="datetime"
                                                            :placeholder="L['请选择']" :disabled-date="disabledDate"
                                                            :shortcuts="shortcuts" @change="inputEvent('priceEndTime')">
                                                       </el-date-picker>
                                                  </div>
                                                  <span class="error_msg">{{ validate.priceEndTime.errMsg }}</span>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="outer8 flex-row" id="deliverTypeName">
                                        <span class="info8">{{ L['指定配送方式：'] }}</span>
                                        <div class="flex_row_start_center " style="margin-left: 20px;cursor: pointer;"
                                             @click="checkRadio('deliver', 1, L['买方自提'])">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.deliverType == 1" />
                                             <div class="outer10 flex-col" v-else></div>
                                             <span class="txt2">{{ L['买方自提'] }}</span>
                                        </div>

                                        <div class="flex_row_start_center" style="margin-left: 30px;cursor: pointer;"
                                             @click="checkRadio('deliver', 2, L['供方承运'])">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.deliverType == 2" />
                                             <div class="outer10 flex-col" v-else></div>
                                             <span class="txt2">{{ L['供方承运'] }}</span>
                                        </div>

                                        <div class="flex_row_start_center" style="margin-left: 30px;cursor: pointer;">
                                             <div class="flex_row_start_center" @click="checkRadio('deliver', 3)">
                                                  <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.deliverType == 3" />
                                                  <div class="outer10 flex-col" v-else></div>
                                                  <span class="txt2">{{ L['其他'] }}</span>
                                             </div>
                                             <div :class="{ validate: validate.deliverTypeName }" style="margin-left: 14px;">
                                                  <div class="outer12 flex-col"
                                                       :class="{ disable_input: inqDTO.deliverType != 3 }">
                                                       <vali-input type="text" v-model="inqDTO.deliverTypeName"
                                                            label="deliverTypeName" maxlength="20"
                                                            :disabled="inqDTO.deliverType != 3"
                                                            v-show="inqDTO.deliverType == 3"></vali-input>

                                                  </div>
                                             </div>

                                        </div>

                                   </div>
                                   <div class="outer13 flex-row justify-between">
                                        <span class="word13">{{ L['详情描述：'] }}</span>
                                        <div class="bd5 flex-col">
                                             <div class="section5 flex-row">
                                                  <textarea cols="30" rows="9" :placeholder="L['请输入您对产品功能、规格、使用场景或其他方面的要求']"
                                                       v-model="inqDTO.description" maxlength="500"></textarea>
                                             </div>
                                             <div class="section6 flex-row">
                                                  <span class="word14">{{ inqDTO.description.length }}/500</span>
                                             </div>
                                        </div>
                                   </div>

                                   <div class="outer_14-1">
                                        <div class="outer14 flex-row justify-between">
                                             <span class="txt3">{{ L['参考文件：'] }}</span>
                                             <div class="flex-row" :class="{ disabledUpload: fileList.length >= 3 }">
                                                  <label for="upload">
                                                       <div class="outer15 flex-col">
                                                            <span class="info10">+&nbsp;{{ L['添加附件'] }}</span>
                                                       </div>
                                                  </label>
                                                  <input type="file" name="" id="upload" size="1"
                                                       accept="image/png,image/jpeg,image/jpg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.doc,.docx,.xls,.xlsx,.pdf"
                                                       @change="changeFile" multiple ref="inputTab">
                                                  <span class="word15">{{ L['（支持添加doc、docx、xls、xlsx、pdf、jpg、jpeg、png格式的文件）']
                                                  }}</span>
                                             </div>

                                        </div>
                                        <div class="outer16 flex-row" v-for="(file, fileIdx) in fileList" :key="fileIdx">
                                             <img class="label5" src="@/assets/inquiry/inq_icon4.png"
                                                  v-if="file.type == 'img'" />

                                             <img class="label5" src="@/assets/inquiry/excel.png"
                                                  v-else-if="file.type == 'xlsx' || file.type == 'xls'" />

                                             <img class="label5" src="@/assets/inquiry/inq_icon10.png" v-else>
                                             <span class="word16">{{ file.type == 'img' ? `${L['图片']}${fileIdx +
                                                  1}.${file.suffix}` : `${L['文档']}${fileIdx + 1}.${file.suffix}` }}</span>

                                             <span class="txt4" @click="clearFileList(fileIdx)">{{ L['删除'] }}</span>
                                        </div>
                                   </div>

                                   <div class="outer18 flex-row justify-between">
                                        <div class="group2 flex-col"></div>
                                        <span class="txt7">{{ L['交易信息'] }}</span>
                                   </div>
                                   <div class="outer19 flex_row_start_center">
                                        <div class="box1">
                                             <span class="txt8">*</span> <span class="txt9">{{ L['结算方式：'] }}</span>
                                        </div>
                                        <div class="box2g flex-row" @click="checkRadio('bill', 1, L['定金发货'])">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 1">
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['定金发货'] }}</span>
                                        </div>

                                        <div class="box2g flex-row" @click="checkRadio('bill', 2, L['全额付款'])">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 2">
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['全额付款'] }}</span>
                                        </div>

                                        <div class="box2g flex-row" @click="checkRadio('bill', 3, L['分期付款'])">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 3">
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['分期付款'] }}</span>
                                        </div>

                                        <div class="box2g flex_row_start_center">
                                             <div class="flex_row_start_center" @click="checkRadio('bill', 4)">
                                                  <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 4">
                                                  <div class="box3 flex-col" v-else></div>
                                                  <span class="info11">{{ L['其他'] }}</span>
                                             </div>

                                             <div>
                                                  <div class="box6 flex-col"
                                                       :class="{ disable_input: inqDTO.billType != 4 }">
                                                       <vali-input type="text" v-model="inqDTO.billTypeName"
                                                            label="billTypeName" :disabled="inqDTO.billType != 4"
                                                            v-show="inqDTO.billType == 4" maxlength="50"></vali-input>
                                                  </div>
                                             </div>


                                        </div>
                                   </div>
                                   <div class="outer20 flex-row">
                                        <div class="box1">
                                             <span class="txt8">*</span>
                                             <span class="txt9">{{ L['发票要求：'] }}</span>
                                        </div>

                                        <div class="box2g flex_row_start_center" @click="checkRadio('invoice', 1)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.invoiceType == 1" />
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['无需发票'] }}</span>
                                        </div>

                                        <div class="box2g flex_row_start_center" @click="checkRadio('invoice', 2)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.invoiceType == 2" />
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['增值税专用发票'] }}</span>
                                        </div>

                                        <div class="box2g flex_row_start_center" @click="checkRadio('invoice', 3)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.invoiceType == 3" />
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">{{ L['增值税普通发票'] }}</span>
                                        </div>


                                   </div>
                                   <div class="outer25 flex-row justify-between">
                                        <div class="layer10 flex-col"></div>
                                        <span class="word20">{{ L['联系人信息'] }}</span>
                                   </div>
                                   <div class="outer26 flex_row_start_start">
                                        <div class="layer11 flex_row_start_start">
                                             <div style="margin-top: 8px;">
                                                  <span class="word21">*</span>
                                                  <span class="info15">{{ L['联系人姓名：'] }}</span>
                                             </div>
                                             <div class="container2">
                                                  <div class="layer12 flex-col">
                                                       <vali-input type="text" v-model="inqDTO.receiverName"
                                                            label="receiverName" maxlength="10"></vali-input>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="layer13 flex_row_start_start">
                                             <div style="margin-top: 9px;">
                                                  <span class="word22">*</span>
                                                  <span class="txt13">{{ L['手机号：'] }}&nbsp;</span>
                                             </div>
                                             <div style="margin-left: 9px;">
                                                  <div class="layer14 flex-col">
                                                       <vali-input type="number" v-model="inqDTO.receiverMobile"
                                                            label="receiverMobile" mode="tel"></vali-input>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="outer27 flex_row_start_center">
                                        <div class="section8">
                                             <span class="txt14">*</span> <span class="word24"></span>
                                             <span class="info17">{{ L['收货地址：'] }}</span>
                                             <span class="word25">&nbsp;&nbsp;</span>
                                        </div>
                                        <div :class="{ validate: validate.receiverAddress.flag }">
                                             <div class="section9 flex-col vBorder">
                                                  <el-cascader ref="cascaderAddr" v-model="inqDTO.receiverAddress"
                                                       :options="areaDataArray" placeholder="请选择所在地区" :props="cascaderProps"
                                                       size="small" clearable @change="inputEvent('receiverAddress')">
                                                  </el-cascader>
                                             </div>
                                             <span class="error_msg">{{ validate.receiverAddress.errMsg }}</span>
                                        </div>
                                   </div>
                                   <div class="outer28 flex_row_start_center">
                                        <div class="section10 flex_row_start_center">
                                             <div>
                                                  <span class="info18">*</span> <span class="info19"></span>
                                                  <span class="word26">{{ L['详细地址：'] }}&nbsp;&nbsp;</span>
                                             </div>
                                             <div :class="{ validate: validate.receiverAddressDetail }">
                                                  <div class="section11 flex_row_start_center">
                                                       <vali-input type="text" v-model="inqDTO.receiverAddressDetail"
                                                            maxlength="50" showCount
                                                            label="receiverAddressDetail"></vali-input>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="outer29 flex-col" @click="initiate_inquiry">
                                        <span class="word28">{{ L['确认'] }}{{ route.query.inquireId ? L['编辑'] : L['发布']
                                        }}</span>
                                   </div>
                              </div>
                         </vali-input-group>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, watch } from 'vue'
import { inqUtils } from '@/utils/inquiry_utils.js'
import areaData from "@/assets/area.json";
import valiInput from '../components/vali-input.vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import valiInputGroup from '../components/vali-input-group.vue';
export default {
     components: {
          valiInput,
          valiInputGroup
     },
     setup() {
          const header_bg = require('@/assets/inquiry/header_bg3.png')
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const value = ref('')

          const { uploadFile, fileList, clearFileList, unitList, formatTime, setFileDTO } = inqUtils()
          const { areaDataArray, cascaderProps, disabledDate } = inqUtils();

          const router = useRouter()
          const route = useRoute()

          const productListDTO = {
               brandName: '',
               buyNum: 1,
               priceMax: '',
               priceMin: '',
               productName: '',
               spec: '',
               unit: '',
               unitName: unitList.value[0],
          }

          const inqDTO = reactive({
               title: '',
               billType: 1,
               billTypeName: L['定金发货'],
               deliverType: 1,
               deliverTypeName: L['买方自提'],
               description: '',
               expectedDeliverDate: '',
               inquireId: '',
               invoiceType: 1,
               priceEndTime: '',
               productList: [{ ...JSON.parse(JSON.stringify(productListDTO)), key: 'p1' }],
               receiverAddress: '',
               receiverAddressDetail: '',
               receiverMobile: '',
               receiverName: '',
               referenceFile: ''
          })

          const validate = reactive({
               productName: {
                    required: true, errMsg: L['请输入产品名称']
               },
               priceMin: {
                    required: true,
                    errMsg: L['请输入最小值'],
                    related: true,
                    related_msg: L['期望单价的最大值不能小于最小值'],
                    notZero: true,
                    notZero_msg: L['必须为大于0的正数']
               },
               priceMax: {
                    required: true,
                    errMsg: L['请输入最大值'],
                    notZero: true,
                    notZero_msg: L['必须为大于0的正数']
               },
               buyNum: {
                    required: true, errMsg: L['请输入产品数量'],
                    notZero: true,
                    notZero_msg: L['必须为大于0的正数']
               },
               expectedDeliverDate: {
                    required: true, errMsg: L['请选择期望收货日期'], flag: false
               },
               priceEndTime: {
                    required: true, errMsg: L['请选择报价截止日期'], flag: false
               },
               receiverAddress: {
                    required: true, errMsg: L['请选择收货地址'], flag: false
               },
               receiverAddressDetail: {
                    required: true, errMsg: L['请填写详细地址']
               },
               receiverMobile: {
                    required: true, errMsg: L['请输入手机号'],
                    checkMobile: true,
                    checkMobile_errMsg: L['格式错误，请输入正确的手机号码']
               },
               receiverName: {
                    required: true, errMsg: L['请输入联系人姓名']
               },
               title: {
                    required: true, errMsg: L['请输入询盘标题']
               },
               deliverTypeName: {
                    required: true, errMsg: L['请填写配送方式']
               },
               billTypeName: {
                    required: true, errMsg: L['请填写结算方式']
               },
          })


          const addProductPanel = () => {
               inqDTO.productList.push({
                    ...JSON.parse(JSON.stringify(productListDTO)),
                    key: `p${inqDTO.productList.length + 1}`
               })
          }

          const removeProPanel = (proIdx) => {
               inqDTO.productList.splice(proIdx, 1)
          }

          const changeFile = (e) => {
               if (fileList.length >= 3) {
                    ElMessage(L['最多上传三个附件'])
                    return
               }
               uploadFile(e, () => {
                    proxy.$refs.inputTab.value = ''
               })
          }


          const inputEvent = (val, index) => {
               if (index != undefined) {
                    validate[val].index = -1
                    validate[val].msg = ''
               } else {
                    validate[val] = ''
               }
          }

          const checkRadio = (checkType, value, valueName) => {
               switch (checkType) {
                    case 'deliver': {
                         inqDTO.deliverType = value
                         inqDTO.deliverTypeName = valueName ? valueName : ''
                         break
                    }
                    case 'bill': {
                         inqDTO.billType = value
                         inqDTO.billTypeName = valueName ? valueName : ''
                         break
                    }
                    case 'invoice': {
                         inqDTO.invoiceType = value
                         break
                    }
               }
          }

          const checkInputEvent = () => {
               let {
                    expectedDeliverDate,
                    priceEndTime,
                    receiverAddress,
               } = inqDTO
               let final_result = true
               let errIdDom = []
               if (!expectedDeliverDate) {
                    validate.expectedDeliverDate.flag = true
                    errIdDom.push('expectedDeliverDate')
                    final_result = false
               }
               if (!priceEndTime) {
                    validate.priceEndTime.flag = true
                    errIdDom.push('priceEndTime')
                    final_result = false
               }
               if (!receiverAddress.length) {
                    validate.receiverAddress.flag = true
                    errIdDom.push('receiverAddress')
                    final_result = false
               }
               return {
                    check_value: final_result,
                    errIdDom
               }
          }


          const intoView = (errIdGroup) => {
               const container = document.querySelector('div.initiate_inquiry_z')
               const idGroup = container.querySelectorAll('[id]')
               for (let id_dom in Array.from(idGroup)) {
                    if (errIdGroup.indexOf(Array.from(idGroup)[id_dom].id) > -1) {
                         document.querySelector(`#${Array.from(idGroup)[id_dom].id}`).scrollIntoView({
                              // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
                              behavior: "smooth",
                              // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
                              block: "center",
                              // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
                              inline: "nearest"
                         })
                         break
                    }
               }
          }


          const getEditDetail = () => {
               proxy.$get('v3/business/front/purchaseInquire/detail', {
                    inquireId: route.query.inquireId
               }).then(res => {
                    if (res.state == 200) {
                         Object.keys(res.data).forEach(item => {
                              if (item == 'receiverAddress') {
                                   inqDTO[item] = res.data[item].split('/')
                              } else if (item == 'referenceFile' && res.data[item]) {
                                   fileList.value = res.data[item].split(',').map(file => {
                                        return setFileDTO({ path: file, url: file })
                                   })
                              } else if (item == 'productList') {
                                   inqDTO[item] = res.data[item].map((vl, ix) => {
                                        return {
                                             key: `p${ix + 1}`,
                                             ...vl
                                        }
                                   })
                              } else {
                                   inqDTO[item] = res.data[item]
                              }
                         })
                    }
               })
          }


          const initiate_inquiry = () => {
               proxy.$refs.valiGroup.startVali((validate_value, errGroup) => {
                    let { check_value, errIdDom } = checkInputEvent()
                    if (validate_value && check_value) {
                         inquiry_request()
                    } else {
                         console.log('error', errGroup)
                         const errIdGroup = errGroup.map(item => item.key || item.label)
                         intoView([...errIdGroup, ...errIdDom])
                    }
               })
          }


          const inquiry_request = () => {
               inqDTO.expectedDeliverDate = formatTime(inqDTO.expectedDeliverDate)
               inqDTO.priceEndTime = formatTime(inqDTO.priceEndTime)
               inqDTO.receiverAddress = inqDTO.receiverAddress.join('/')
               inqDTO.referenceFile = fileList.value.map(i => i.path).join(',')
               inqDTO.productList.map(item => {
                    item.priceMax = Number(item.priceMax).toFixed(2)
                    item.priceMin = Number(item.priceMin).toFixed(2)
               })

               let url
               if (route.query.from && route.query.inquireId) {
                    url = 'v3/business/front/purchaseInquire/update'
               } else {
                    url = 'v3/business/front/purchaseInquire/add'
               }


               proxy.$post(url, inqDTO, 'json').then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         setTimeout(() => {
                              router.push('/member/inquiry/myInq')
                         }, 500)
                    } else {
                         inqDTO.receiverAddress = inqDTO.receiverAddress.split('/')
                         ElMessage.error(res.msg)
                    }
               })
          }

          const querySearch = (queryString, cb) => {
               const results = queryString ? unitList.value.filter(val => val.indexOf(queryString) === 0) : unitList.value
               console.log(results, 'queryString')
               cb(results)
          }

          const handleSelect = (item, pro) => {
               pro.unitName = item
          }

          const autoBlur = (e, pro) => {
               if (!pro.unitName.trim()) {
                    pro.unitName = unitList.value[0]
               }
          }

          const priceRelate = (pro) => {
               return () => {
                    return pro.priceMax >= pro.priceMin
               }
          }

          const clearInqDTO = () => {
               Object.keys(inqDTO).forEach(item => {
                    switch (item) {
                         case 'billTypeName': {
                              inqDTO[item] = L['定金发货']
                              break
                         }
                         case 'deliverTypeName': {
                              inqDTO[item] = L['买方自提']
                              break
                         }
                         case 'productList': {
                              inqDTO[item] = [{ ...JSON.parse(JSON.stringify(productListDTO)), key: 'p1' }]
                              break
                         }

                         case 'deliverType': case 'billType': case 'invoiceType': {
                              inqDTO[item] = 1
                              break
                         }
                         default: {
                              inqDTO[item] = ''
                         }
                    }
               })
          }


          watch(() => route.query, () => {
               if (route.query.from && route.query.inquireId) {
                    getEditDetail()
               } else {
                    clearInqDTO()
               }
          })

          onMounted(() => {
               if (route.query.from && route.query.inquireId) {
                    getEditDetail()
               }
          })



          return {
               priceRelate,
               autoBlur,
               querySearch,
               handleSelect,
               header_bg,
               inqDTO,
               addProductPanel,
               removeProPanel,
               validate,
               checkRadio,
               changeFile,
               fileList,
               clearFileList,
               inputEvent,
               initiate_inquiry,
               cascaderProps,
               areaDataArray,
               unitList,
               route,
               disabledDate,
               L
          }

     }
}
</script>

<style lang="scss">
.section9 {
     .el-input__inner {
          border: none;
     }
}

.my-autocomplete {

     .el-autocomplete-suggestion {
          width: 70px !important;


     }



     li {
          line-height: normal;
          padding: 7px;

          .name {
               text-overflow: ellipsis;
               overflow: hidden;
          }

          .addr {
               font-size: 12px;
               color: #b4b4b4;
          }
     }
}

.block3 {
     .el-input__inner {
          border: none;
          padding-left: 4px;
          padding-right: 10px;
          height: 32px;
     }

     .el-input__icon {
          width: 20px;
     }

     .el-input__icon {
          right: 2px;
     }
}
</style>

<style lang="scss" scoped>
.inquiry_header {
     position: relative;
     height: 300px;

     .header_bg {
          background-position: center center;
          width: 100%;
          height: 301px;
          position: absolute;
          left: 0;
          top: 0;
          /* margin-left: -960px; */
          background-repeat: no-repeat;
          background-size: cover;
     }
}

#upload {
     display: none;
}

.validate {
     .vBorder {
          border-color: #F30606 !important;
     }

     .error_msg {
          display: block;
          margin-top: 2px;
     }
}

.error_msg {
     font-size: 12px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #F30606;
     display: none;
}

/*** 消除input元素 type="number" 时默认的 加减按钮*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

/*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
input[type="number"] {
     -moz-appearance: textfield;
}

input {
     border: none;
     outline: none;
     height: 32px;
}

.initiate_inquiry_z {
     background: $colorJ;
     padding-top: 20px;
     width: 100%;
     padding-bottom: 30px;

     .init_main {
          width: 1200px;
          background: #FFFFFF;
          margin: 0 auto;

          .bd1 {
               background-color: rgba(255, 255, 255, 1);
               z-index: 8;
               position: relative;
               padding: 20px;

               .bd2 {

                    .outer1 {
                         width: 81px;
                         height: 18px;
                         margin-left: 1px;

                         .layer1 {
                              background-color: rgba(40, 95, 222, 1);
                              width: 6px;
                              height: 18px;
                         }

                         .info1 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              margin-left: 10px;
                         }
                    }

                    .outer2 {
                         width: 1158px;
                         margin: 20px 0 0 3px;

                         .bd3 {
                              margin-top: 12px;

                              .bd31 {
                                   margin-top: 10px;
                              }

                              .word2 {
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }

                              .info2 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }
                         }

                         .bd4 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 36px;
                              width: 1070px;

                              .bd4_title {
                                   width: 100%;
                              }

                              .word3 {
                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;

                              }
                         }
                    }

                    .info3 {
                         color: rgba(18, 18, 18, 1);
                         font-size: 14px;
                         margin: 30px 0 0 1px;
                    }

                    .outer3 {
                         margin-top: 13px;
                    }


                    .outer5 {
                         border-radius: 4px;
                         height: 35px;
                         border: 1px dashed rgba(40, 95, 222, 1);
                         width: 150px;
                         margin: 24px 0 0 505px;
                         cursor: pointer;
                         text-align: center;

                         .info6 {
                              line-height: 35px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;

                         }
                    }

                    .outer6g {
                         margin: 30px 0 0 1px;

                         .section1g {
                              margin-top: 11px;

                              .word6g {
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                              }

                              .word7g {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }
                         }

                         .section2g {
                              background-color: rgba(255, 255, 255, 1);
                              margin-left: 11px;

                              .section21g {
                                   border: 1px solid #fff;
                              }

                              .el-input__inner {
                                   border: unset;
                              }
                         }

                         .section3g {
                              margin: 11px 0 0 45px;

                              .word8g {
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                              }

                              .word9g {
                                   font-size: 14px;
                                   color: rgba(51, 51, 51, 1);
                              }
                         }


                    }

                    .outer8 {
                         margin: 22px 0 0 12px;

                         .info8 {
                              width: 92px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 7px;
                              display: block;
                         }

                         .outer9 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              height: 18px;
                              border: 1px solid rgba(40, 95, 222, 1);
                              width: 18px;
                              margin: 5px 0 0 15px;

                              .group1 {
                                   background-color: rgba(40, 95, 222, 1);
                                   border-radius: 50%;
                                   width: 10px;
                                   height: 10px;
                                   margin: 4px 0 0 4px;
                              }
                         }

                         .txt2 {

                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              display: block;
                              margin: 0px 0 0 8px;
                         }

                         .outer10 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                         }

                         .word11 {
                              width: 58px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .outer11 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                              margin: 5px 0 0 35px;
                         }

                         .word12 {
                              width: 28px;
                              height: 13px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .outer12 {
                              width: 100px;
                              height: 28px;

                              &.disable_input {
                                   background-color: rgba(201, 201, 201, 1);
                              }
                         }
                    }

                    .outer13 {
                         width: 1120px;
                         height: 164px;
                         margin: 24px 0 0 38px;

                         .word13 {
                              width: 63px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 7px;
                              display: block;
                              margin-right: 2px;
                         }

                         .bd5 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 164px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 1046px;
                              justify-content: flex-center;

                              .section5 {
                                   margin: 9px 10px 0 10px;

                                   textarea {
                                        width: 100%;
                                        resize: none;
                                        color: rgba(153, 153, 153, 1);
                                        border: none;
                                        outline: none;
                                        line-height: 20px;
                                        height: 120px;
                                   }
                              }

                              .section6 {

                                   align-self: flex-end;
                                   margin-right: 10px;
                                   margin-bottom: 10px;
                                   margin-top: 10px;

                                   .word14 {
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 13px;
                                        display: block;
                                   }
                              }
                         }
                    }

                    .outer14 {
                         width: 618px;
                         height: 35px;
                         margin: 30px 0 0 39px;

                         .txt3 {
                              width: 63px;
                              height: 15px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 15px;
                              margin-top: 10px;
                              display: block;
                              margin-right: 4px
                         }

                         .disabledUpload {
                              position: relative;

                              &:after {
                                   content: '';
                                   position: absolute;
                                   top: 0;
                                   left: 0;
                                   right: 0;
                                   bottom: 0;
                                   opacity: 0.3;
                              }



                              .outer15 {
                                   border: 1px dashed #999;
                                   color: #999;

                                   .info10 {
                                        color: #999;
                                   }
                              }
                         }

                         .outer15 {
                              border-radius: 4px;
                              height: 35px;
                              border: 1px dashed rgba(40, 95, 222, 1);
                              width: 150px;
                              cursor: pointer;

                              .info10 {
                                   width: 70px;
                                   height: 14px;
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   display: block;
                                   margin: 10px 0 0 40px;
                              }
                         }

                         .word15 {
                              width: 384px;
                              height: 14px;
                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 11px;
                              display: block;
                         }
                    }

                    .outer16 {
                         width: 165px;
                         height: 31px;
                         margin: 21px 0 0 115px;

                         .label5 {
                              width: 31px;
                              height: 31px;
                         }

                         .word16 {
                              width: 71px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 6px 0 0 14px;
                         }

                         .txt4 {
                              width: 25px;
                              height: 12px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              display: block;
                              margin: 7px 0 0 24px;
                              cursor: pointer;

                              &:hover {
                                   text-decoration: underline;
                              }
                         }
                    }


                    .outer18 {
                         width: 81px;
                         height: 18px;
                         margin: 30px 0 0 1px;

                         .group2 {
                              background-color: rgba(40, 95, 222, 1);
                              width: 6px;
                              height: 18px;
                         }

                         .txt7 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              margin-left: 10px;
                         }
                    }

                    .outer19 {

                         margin: 17px 0 0 17px;

                         .box1 {
                              width: 74px;
                              height: 14px;
                              font-size: 0;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;

                              .txt8 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt9 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .box2g {
                              margin-left: 23px;

                              img {
                                   width: 18px;
                                   height: 18px;
                              }

                              .box3 {
                                   background-color: rgba(255, 255, 255, 1);
                                   border-radius: 50%;
                                   width: 18px;
                                   height: 18px;
                                   border: 2px solid rgba(201, 201, 201, 1);
                              }

                              .info11 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   margin: 0px 0 0 8px;
                              }
                         }

                         .word17 {
                              width: 57px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .box4 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                              margin: 5px 0 0 39px;
                         }

                         .word18 {
                              width: 57px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .box5 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                              margin: 5px 0 0 43px;
                         }

                         .info12 {
                              width: 28px;
                              height: 13px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .box6 {
                              width: 98px;
                              height: 28px;
                              margin-left: 13px;

                              &.disable_input {
                                   background-color: rgba(201, 201, 201, 1);
                              }
                         }
                    }

                    .outer20 {
                         width: 508px;
                         height: 18px;
                         margin: 16px 0 0 17px;

                         .box2g {
                              margin-left: 23px;

                              img {
                                   width: 18px;
                                   height: 18px;
                              }

                              .box3 {
                                   background-color: rgba(255, 255, 255, 1);
                                   border-radius: 50%;
                                   width: 18px;
                                   height: 18px;
                                   border: 2px solid rgba(201, 201, 201, 1);
                              }

                              .info11 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   margin: 0px 0 0 8px;
                              }
                         }

                         .box1 {
                              width: 74px;
                              height: 14px;
                              font-size: 0;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;

                              .txt8 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt9 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                    }

                    .outer25 {
                         width: 98px;
                         height: 18px;
                         margin: 32px 0 0 1px;

                         .layer10 {
                              background-color: rgba(40, 95, 222, 1);
                              width: 6px;
                              height: 18px;
                         }

                         .word20 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              margin-left: 10px;
                         }
                    }

                    .outer26 {

                         margin: 24px 0 0 16px;

                         .layer11 {

                              .word21 {
                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                              }

                              .info15 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                              }

                              .info16 {
                                   width: 89px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .container2 {
                              margin-left: 4px;

                         }

                         .layer12 {
                              width: 200px;
                              height: 32px;
                         }

                         .layer13 {

                              margin: 0px 0 0 25px;

                              .word22 {

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }

                              .txt12 {
                                   width: 60px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt13 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .word23 {
                                   width: 60px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .layer14 {
                              width: 200px;
                              height: 32px;
                         }
                    }

                    .outer27 {
                         margin: 20px 0 0 22px;

                         .section8 {

                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;

                              .txt14 {

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                              }

                              .word24 {

                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   text-align: left;
                                   line-height: 14px;
                              }

                              .info17 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }

                              .word25 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .section9 {
                              height: 35px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 494px;
                              border-radius: 5px;

                              .layer15 {
                                   width: 474px;
                                   height: 12px;
                                   margin: 10px 0 0 10px;

                                   .txt15 {
                                        width: 87px;
                                        height: 12px;
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        display: block;
                                   }

                                   .label7 {
                                        width: 13px;
                                        height: 7px;
                                        margin-top: 3px;
                                   }
                              }
                         }
                    }

                    .outer28 {
                         width: 1146px;
                         margin: 20px 0 0 22px;

                         .section10 {

                              margin-top: 9px;

                              .info18 {
                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                              }

                              .info19 {
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                              }

                              .word26 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }
                         }

                         .section11 {
                              height: 34px;
                              width: 1047px;

                              .validate_input {
                                   width: 100%;
                              }

                              input {
                                   flex: 1;
                              }

                              .word27 {

                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;
                                   margin-right: 10px;
                              }
                         }
                    }

                    .outer29 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 6px;
                         height: 42px;
                         width: 200px;
                         margin: 57px 0 0 481px;
                         cursor: pointer;

                         .word28 {
                              width: 65px;
                              height: 16px;
                              color: rgba(255, 255, 255, 1);
                              font-size: 16px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 16px;
                              display: block;
                              margin: 13px 0 0 68px;
                         }
                    }
               }

               .bd6 {
                    z-index: 18;
                    border: 1px solid rgba(220, 220, 220, 1);

                    .outer30 {
                         .block2 {
                              padding-left: 69px;
                              background-color: rgba(247, 248, 250, 1);
                              height: 45px;
                              border-bottom: 1px solid gainsboro;

                              .main1 {
                                   line-height: 45px;
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;

                                   .word29 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                   }

                                   .word30 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }
                              }

                              .word31 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   line-height: 45px;
                                   margin-left: 170px;
                              }

                              .txt16 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   line-height: 45px;
                                   margin-left: 170px;
                              }

                              .main2 {
                                   line-height: 45px;
                                   margin-left: 170px;

                                   .info20 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                   }

                                   .word32 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }
                              }

                              .main3 {
                                   line-height: 45px;
                                   margin-left: 160px;

                                   .word33 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                   }

                                   .word34 {

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;

                                   }
                              }
                         }

                         .block3 {
                              background: #fff;
                              border-bottom: 1px solid gainsboro;

                              .layer16 {
                                   margin: 19px 0 19px 31px;

                                   .section12 {
                                        background-color: rgba(255, 255, 255, 1);
                                        width: 170px;
                                        height: 36px;
                                   }

                                   .section13 {
                                        background-color: rgba(255, 255, 255, 1);
                                        width: 170px;
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        margin-left: 46px;
                                        padding: 0 10px;
                                        border-radius: 5px;
                                   }



                                   .section15 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 130px;
                                        border-radius: 5px;

                                        .layer17 {
                                             height: 36px;
                                             position: relative;

                                             // input {
                                             //      width: 55px;
                                             // }

                                             .vali_input_buyNum {
                                                  width: 145px !important;
                                                  height: 34px;
                                                  border: none;
                                                  outline: none;
                                                  background: transparent;
                                                  border-radius: 5px 0 0 5px !important;
                                             }

                                             .icon2 {
                                                  width: 1px;
                                                  height: 36px;
                                             }

                                             .con1 {
                                                  height: 34px;
                                                  border-left: 1px solid #c9c9c9;
                                             }

                                             .info21 {
                                                  margin-left: 5px;
                                                  color: rgba(51, 51, 51, 1);
                                                  font-size: 12px;
                                             }

                                             .layer18 {
                                                  position: absolute;
                                                  width: 60px;
                                                  top: -1px;
                                                  right: -60px;
                                                  height: 36px;
                                                  border: 1px solid #c9c9c9;

                                             }

                                             .label8 {
                                                  width: 13px;
                                                  height: 7px;
                                                  margin-left: 6px;
                                             }
                                        }
                                   }

                                   .container1 {
                                        margin: 1px 0 0 64px;

                                   }

                                   .section16 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        width: 95px;
                                   }

                                   .info22 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 24px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 24px;
                                        display: block;
                                        margin: 5px;
                                   }

                                   .section17 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        width: 96px;
                                   }

                                   .section18 {
                                        margin-left: 15px;
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;

                                        img {
                                             width: 18px;
                                             height: 18px;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}
</style>